import $ from 'jquery';
import 'what-input';
import 'magnific-popup';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();


$(document ).ready(function() {
    $(function() {
        $("a.no-link").click(function(){
            return false;
        });
    });

    // Add it after jquery.magnific-popup.js and before first initialization code
    $.extend(true, $.magnificPopup.defaults, {
        tClose: 'Afsluiten (Esc)',
        tLoading: 'Bezig met laden...',
        gallery: {
            tPrev: 'Vorige', // Alt text on left arrow
            tNext: 'Volgende', // Alt text on right arrow
            tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
        },
        image: {
            tError: '<a href="%url%">De afbeelding</a> kan niet worden geladen' // Error message when image could not be loaded
        },
        ajax: {
            tError: '<a href="%url%">De inhoud</a> kan niet worden geladen.' // Error message when ajax request failed
        }
    });
    if ($('.gallery').length > 0)
    {
        $('.gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            closeOnContentClick: true,
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            }
        });

    }

});